<template>
  <v-card-text class="pa-0 elevation-0">
    <v-toolbar flat color="transparent">
      <v-icon class="grey--text mr-3">mdi-briefcase-clock-outline</v-icon>
      <h2 class="heading font-weight-normal grey--text">{{ $t('offers.title') }}</h2>
      <v-spacer></v-spacer>
      <v-icon
        id="btnTable"
        @click="activeView($event)"
        class="mr-6"
        :class="[Offers.state.isTable ? 'active' : '']"
        >mdi-format-list-bulleted-square</v-icon
      >
      <v-icon
        id="btnGrid"
        @click="activeView($event)"
        class="mr-6"
        :class="[!Offers.state.isTable ? 'active' : '']"
        >mdi-view-grid-outline</v-icon
      >
      <v-tooltip :bottom="true">
        <template v-slot:activator="{ on }">
          <v-badge
            :value="Object.keys(Offers.state.appliedFilters).length"
            :content="Object.keys(Offers.state.appliedFilters).length"
            overlap
            color="blue lighten-1"
          >
            <v-btn v-on="on" @click="show = !show" depressed small class="ml-2">
              <v-icon>{{ show ? 'mdi-filter-minus-outline' : 'mdi-filter-plus-outline' }}</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>{{
          Object.keys(Offers.state.appliedFilters).length
            ? `${Object.keys(Offers.state.appliedFilters).length} filters have been applied`
            : 'No filters applied yet'
        }}</span>
      </v-tooltip>
    </v-toolbar>
    <transition name="scale-transition">
      <v-divider v-if="show" class="mx-5"></v-divider>
    </transition>
    <transition name="scale-transition">
      <v-card v-if="show" color="transparent" elevation="0" class="ma-0 pa-0">
        <v-form v-model="filtersForm_valid" ref="filtersForm" lazy-validation>
          <v-card-text class="pb-0">
            <v-col cols="12">
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <span class="subtitle-1 font-weight-light">Offer ID</span>
                  <v-text-field
                    :value="Offers.state.selectedFilters._id"
                    @input="setSelectedFilter('_id', $event)"
                    :rules="[
                      (v) =>
                        isValidObjectId(v) ||
                        v === null ||
                        v === undefined ||
                        v === '' ||
                        $t('commons.rules.errorInvalidId'),
                    ]"
                    label="Offer ID"
                    clearable
                    flat
                    dense
                    solo-inverted
                    hide-details="auto"
                    prepend-inner-icon="mdi-pen"
                  ></v-text-field>
                  <span class="subtitle-1 font-weight-light">Offer Name</span>
                  <v-text-field
                    :value="Offers.state.selectedFilters.name"
                    @input="setSelectedFilter('name', $event)"
                    label="Offer Name"
                    clearable
                    flat
                    dense
                    solo-inverted
                    hide-details
                    prepend-inner-icon="mdi-pen"
                  ></v-text-field>
                  <span class="subtitle-1 font-weight-light">Verticals</span>
                  <v-autocomplete
                    v-model="Offers.state.selectedFilters._categories"
                    :items="Categories.state.items"
                    @input="setSelectedFilter('_categories', $event)"
                    item-text="name"
                    item-value="_id"
                    label="Verticals"
                    multiple
                    solo
                    chips
                    hide-details
                    dense
                    flat
                    solo-inverted
                    clearable
                    prepend-inner-icon="mdi-cursor-pointer"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="
                          setSelectedFilter(
                            '_categories',
                            removeOnList(Offers.state.selectedFilters._categories, data.item._id)
                          )
                        "
                        class="ma-1"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <span class="subtitle-1 font-weight-light">Payout Type</span>
                  <v-autocomplete
                    v-model="Offers.state.selectedFilters._payoutTypes"
                    :items="PayoutTypes.state.items"
                    item-text="name"
                    item-value="_id"
                    label="Payout Type"
                    multiple
                    solo
                    chips
                    hide-details
                    dense
                    flat
                    solo-inverted
                    clearable
                    prepend-inner-icon="mdi-cursor-pointer"
                    @input="setSelectedFilter('_payoutTypes', $event)"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="
                          setSelectedFilter(
                            '_payoutTypes',
                            removeOnList(Offers.state.selectedFilters._payoutTypes, data.item._id)
                          )
                        "
                        class="ma-1"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <span class="subtitle-1 font-weight-light">Country</span>
                  <v-autocomplete
                    v-model="Offers.state.selectedFilters._country"
                    :items="Countries.state.items"
                    item-text="name"
                    item-value="code"
                    label="Country"
                    multiple
                    solo
                    chips
                    hide-details
                    dense
                    flat
                    solo-inverted
                    clearable
                    prepend-inner-icon="mdi-cursor-pointer"
                    @input="setSelectedFilter('_country', $event)"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="
                          setSelectedFilter(
                            '_country',
                            removeOnList(Offers.state.selectedFilters._country, data.item.code)
                          )
                        "
                        class="ma-1"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <span class="subtitle-1 font-weight-light">Device Client</span>
                  <v-autocomplete
                    v-model="Offers.state.selectedFilters.deviceClient"
                    :items="Devices.state.DeviceClientItems"
                    :loading="Devices.state.DeviceClientItemsLoading"
                    label="Device Client"
                    multiple
                    solo
                    chips
                    hide-details
                    dense
                    flat
                    solo-inverted
                    clearable
                    prepend-inner-icon="mdi-cursor-pointer"
                    @input="setSelectedFilter('deviceClient', $event)"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="
                          setSelectedFilter(
                            'deviceClient',
                            removeOnList(Offers.state.selectedFilters.deviceClient, data.item)
                          )
                        "
                        class="ma-1"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <span class="subtitle-1 font-weight-light">Device Os</span>
                  <v-autocomplete
                    v-model="Offers.state.selectedFilters.deviceOs"
                    :items="Devices.state.DeviceOsItems"
                    :loading="Devices.state.DeviceOsItemsLoading"
                    label="Device Os"
                    multiple
                    solo
                    chips
                    hide-details
                    dense
                    flat
                    solo-inverted
                    clearable
                    prepend-inner-icon="mdi-cursor-pointer"
                    @input="setSelectedFilter('deviceOs', $event)"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="
                          setSelectedFilter(
                            'deviceOs',
                            removeOnList(Offers.state.selectedFilters.deviceOs, data.item)
                          )
                        "
                        class="ma-1"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <span class="subtitle-1 font-weight-light">Device Type</span>
                  <v-autocomplete
                    v-model="Offers.state.selectedFilters.deviceType"
                    :items="Devices.state.DeviceTypeItems"
                    :loading="Devices.state.DeviceTypeItemsLoading"
                    label="Device Type"
                    multiple
                    solo
                    chips
                    hide-details
                    dense
                    flat
                    solo-inverted
                    clearable
                    prepend-inner-icon="mdi-cursor-pointer"
                    @input="setSelectedFilter('deviceType', $event)"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="
                          setSelectedFilter(
                            'deviceType',
                            removeOnList(Offers.state.selectedFilters.deviceType, data.item)
                          )
                        "
                        class="ma-1"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                  <span class="subtitle-1 font-weight-light">Offer Status</span>
                  <v-autocomplete
                    v-model="Offers.state.selectedFilters.validatedStatus"
                    :items="offerStatus"
                    item-text="name"
                    item-value="value"
                    label="Offer Status"
                    multiple
                    chips
                    hide-details
                    dense
                    flat
                    solo-inverted
                    clearable
                    prepend-inner-icon="mdi-cursor-pointer"
                    @input="setSelectedFilter('validatedStatus', $event)"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="
                          setSelectedFilter(
                            'validatedStatus',
                            removeOnList(
                              Offers.state.selectedFilters.validatedStatus,
                              data.item.value
                            )
                          )
                        "
                        class="ma-1"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions class="pt-0 pr-7 pb-6">
            <v-spacer />
            <v-btn @click="onApplyFilters()" depressed>
              <v-icon class="mr-1">{{ Icons.send }}</v-icon>
              <span class="text-capitalize">{{ $t('commons.filter.applyFilters') }}</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </transition>
  </v-card-text>
</template>
<script>
import { inject, onMounted, onUnmounted, reactive, ref } from '@vue/composition-api';
import _ from 'lodash';
import { isValidObjectId } from '@/store/constants/Validations';

export default {
  name: 'offer-filters',
  components: {},
  data: () => {
    return {
      filtersForm_valid: true,
    };
  },
  setup(props, { emit }) {
    const Categories = inject('Categories');
    const PayoutTypes = inject('PayoutTypes');
    const Countries = inject('Countries');
    const Offers = inject('Offers');
    const Devices = inject('Devices');
    const filtersForm = ref(null);
    let idView = '';
    const self = reactive({
      show: Object.keys(Offers.state.appliedFilters).length,
      offerStatus: [
        { name: 'Approved', value: 'Approved' },
        { name: 'Apply to run', value: 'Apply to run' },
        { name: 'Applied', value: 'Applied' },
        { name: 'Rejected', value: 'Rejected' },
      ],
    });
    const onApplyFilters = () => {
      if (filtersForm.value.validate()) {
        Offers.actions.applyFilters();
        emit('resetPagination');
      }
    };
    const setSelectedFilter = (key, value) => {
      Offers.actions.setSelectedFilter(key, value);
    };
    const removeOnList = (list, value) => {
      const index = _.indexOf(list, value);
      if (index >= 0) {
        list.splice(index, 1);
      }
      return list;
    };
    const activeView = (e) => {
      idView = e.currentTarget.id.replace('btn', '').toLowerCase();
      if (idView === 'table') {
        setOffersView(idView);
        emit('setOffersView', true);
      } else {
        setOffersView(idView);
        emit('setOffersView', false);
      }
    };
    function setOffersView(id) {
      if (id === 'table') {
        Offers.actions.setOffersView(true);
      } else {
        Offers.actions.setOffersView(false);
      }
    }
    onUnmounted(() => {
      Offers.actions.setAppliedToSelectedFilters();
    });
    onMounted(() => {
      Categories.actions.loadItems();
      PayoutTypes.actions.loadItems();
      Countries.actions.loadItems();
    });
    return {
      ...self,
      Categories,
      PayoutTypes,
      Countries,
      Offers,
      Devices,
      removeOnList,
      activeView,
      setOffersView,
      isValidObjectId,
      filtersForm,
      onApplyFilters,
      setSelectedFilter,
    };
  },
};
</script>
<style lang="scss">
.theme--light.v-icon {
  &:after {
    border-radius: 0;
  }
  &.active {
    color: #42a5f5;
    pointer-events: none;
  }
}
</style>
