var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"pa-0 elevation-0"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-icon',{staticClass:"grey--text mr-3"},[_vm._v("mdi-briefcase-clock-outline")]),_c('h2',{staticClass:"heading font-weight-normal grey--text"},[_vm._v(_vm._s(_vm.$t('offers.title')))]),_c('v-spacer'),_c('v-icon',{staticClass:"mr-6",class:[_vm.Offers.state.isTable ? 'active' : ''],attrs:{"id":"btnTable"},on:{"click":function($event){return _vm.activeView($event)}}},[_vm._v("mdi-format-list-bulleted-square")]),_c('v-icon',{staticClass:"mr-6",class:[!_vm.Offers.state.isTable ? 'active' : ''],attrs:{"id":"btnGrid"},on:{"click":function($event){return _vm.activeView($event)}}},[_vm._v("mdi-view-grid-outline")]),_c('v-tooltip',{attrs:{"bottom":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-badge',{attrs:{"value":Object.keys(_vm.Offers.state.appliedFilters).length,"content":Object.keys(_vm.Offers.state.appliedFilters).length,"overlap":"","color":"blue lighten-1"}},[_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"depressed":"","small":""},on:{"click":function($event){_vm.show = !_vm.show}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.show ? 'mdi-filter-minus-outline' : 'mdi-filter-plus-outline'))])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(Object.keys(_vm.Offers.state.appliedFilters).length ? ((Object.keys(_vm.Offers.state.appliedFilters).length) + " filters have been applied") : 'No filters applied yet'))])])],1),_c('transition',{attrs:{"name":"scale-transition"}},[(_vm.show)?_c('v-divider',{staticClass:"mx-5"}):_vm._e()],1),_c('transition',{attrs:{"name":"scale-transition"}},[(_vm.show)?_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"color":"transparent","elevation":"0"}},[_c('v-form',{ref:"filtersForm",attrs:{"lazy-validation":""},model:{value:(_vm.filtersForm_valid),callback:function ($$v) {_vm.filtersForm_valid=$$v},expression:"filtersForm_valid"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Offer ID")]),_c('v-text-field',{attrs:{"value":_vm.Offers.state.selectedFilters._id,"rules":[
                    function (v) { return _vm.isValidObjectId(v) ||
                      v === null ||
                      v === undefined ||
                      v === '' ||
                      _vm.$t('commons.rules.errorInvalidId'); } ],"label":"Offer ID","clearable":"","flat":"","dense":"","solo-inverted":"","hide-details":"auto","prepend-inner-icon":"mdi-pen"},on:{"input":function($event){return _vm.setSelectedFilter('_id', $event)}}}),_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Offer Name")]),_c('v-text-field',{attrs:{"value":_vm.Offers.state.selectedFilters.name,"label":"Offer Name","clearable":"","flat":"","dense":"","solo-inverted":"","hide-details":"","prepend-inner-icon":"mdi-pen"},on:{"input":function($event){return _vm.setSelectedFilter('name', $event)}}}),_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Verticals")]),_c('v-autocomplete',{attrs:{"items":_vm.Categories.state.items,"item-text":"name","item-value":"_id","label":"Verticals","multiple":"","solo":"","chips":"","hide-details":"","dense":"","flat":"","solo-inverted":"","clearable":"","prepend-inner-icon":"mdi-cursor-pointer"},on:{"input":function($event){return _vm.setSelectedFilter('_categories', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"small":"","input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.setSelectedFilter(
                          '_categories',
                          _vm.removeOnList(_vm.Offers.state.selectedFilters._categories, data.item._id)
                        )}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,false,702498601),model:{value:(_vm.Offers.state.selectedFilters._categories),callback:function ($$v) {_vm.$set(_vm.Offers.state.selectedFilters, "_categories", $$v)},expression:"Offers.state.selectedFilters._categories"}}),_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Payout Type")]),_c('v-autocomplete',{attrs:{"items":_vm.PayoutTypes.state.items,"item-text":"name","item-value":"_id","label":"Payout Type","multiple":"","solo":"","chips":"","hide-details":"","dense":"","flat":"","solo-inverted":"","clearable":"","prepend-inner-icon":"mdi-cursor-pointer"},on:{"input":function($event){return _vm.setSelectedFilter('_payoutTypes', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"small":"","input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.setSelectedFilter(
                          '_payoutTypes',
                          _vm.removeOnList(_vm.Offers.state.selectedFilters._payoutTypes, data.item._id)
                        )}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,false,4069483177),model:{value:(_vm.Offers.state.selectedFilters._payoutTypes),callback:function ($$v) {_vm.$set(_vm.Offers.state.selectedFilters, "_payoutTypes", $$v)},expression:"Offers.state.selectedFilters._payoutTypes"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Country")]),_c('v-autocomplete',{attrs:{"items":_vm.Countries.state.items,"item-text":"name","item-value":"code","label":"Country","multiple":"","solo":"","chips":"","hide-details":"","dense":"","flat":"","solo-inverted":"","clearable":"","prepend-inner-icon":"mdi-cursor-pointer"},on:{"input":function($event){return _vm.setSelectedFilter('_country', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"small":"","input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.setSelectedFilter(
                          '_country',
                          _vm.removeOnList(_vm.Offers.state.selectedFilters._country, data.item.code)
                        )}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,false,3415023286),model:{value:(_vm.Offers.state.selectedFilters._country),callback:function ($$v) {_vm.$set(_vm.Offers.state.selectedFilters, "_country", $$v)},expression:"Offers.state.selectedFilters._country"}}),_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Device Client")]),_c('v-autocomplete',{attrs:{"items":_vm.Devices.state.DeviceClientItems,"loading":_vm.Devices.state.DeviceClientItemsLoading,"label":"Device Client","multiple":"","solo":"","chips":"","hide-details":"","dense":"","flat":"","solo-inverted":"","clearable":"","prepend-inner-icon":"mdi-cursor-pointer"},on:{"input":function($event){return _vm.setSelectedFilter('deviceClient', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"small":"","input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.setSelectedFilter(
                          'deviceClient',
                          _vm.removeOnList(_vm.Offers.state.selectedFilters.deviceClient, data.item)
                        )}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item)+" ")])]}}],null,false,1979807068),model:{value:(_vm.Offers.state.selectedFilters.deviceClient),callback:function ($$v) {_vm.$set(_vm.Offers.state.selectedFilters, "deviceClient", $$v)},expression:"Offers.state.selectedFilters.deviceClient"}}),_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Device Os")]),_c('v-autocomplete',{attrs:{"items":_vm.Devices.state.DeviceOsItems,"loading":_vm.Devices.state.DeviceOsItemsLoading,"label":"Device Os","multiple":"","solo":"","chips":"","hide-details":"","dense":"","flat":"","solo-inverted":"","clearable":"","prepend-inner-icon":"mdi-cursor-pointer"},on:{"input":function($event){return _vm.setSelectedFilter('deviceOs', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"small":"","input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.setSelectedFilter(
                          'deviceOs',
                          _vm.removeOnList(_vm.Offers.state.selectedFilters.deviceOs, data.item)
                        )}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item)+" ")])]}}],null,false,2660280188),model:{value:(_vm.Offers.state.selectedFilters.deviceOs),callback:function ($$v) {_vm.$set(_vm.Offers.state.selectedFilters, "deviceOs", $$v)},expression:"Offers.state.selectedFilters.deviceOs"}}),_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Device Type")]),_c('v-autocomplete',{attrs:{"items":_vm.Devices.state.DeviceTypeItems,"loading":_vm.Devices.state.DeviceTypeItemsLoading,"label":"Device Type","multiple":"","solo":"","chips":"","hide-details":"","dense":"","flat":"","solo-inverted":"","clearable":"","prepend-inner-icon":"mdi-cursor-pointer"},on:{"input":function($event){return _vm.setSelectedFilter('deviceType', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"small":"","input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){_vm.setSelectedFilter(
                          'deviceType',
                          _vm.removeOnList(_vm.Offers.state.selectedFilters.deviceType, data.item)
                        )}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item)+" ")])]}}],null,false,4149988476),model:{value:(_vm.Offers.state.selectedFilters.deviceType),callback:function ($$v) {_vm.$set(_vm.Offers.state.selectedFilters, "deviceType", $$v)},expression:"Offers.state.selectedFilters.deviceType"}}),_c('span',{staticClass:"subtitle-1 font-weight-light"},[_vm._v("Offer Status")]),_c('v-autocomplete',{attrs:{"items":_vm.offerStatus,"item-text":"name","item-value":"value","label":"Offer Status","multiple":"","chips":"","hide-details":"","dense":"","flat":"","solo-inverted":"","clearable":"","prepend-inner-icon":"mdi-cursor-pointer"},on:{"input":function($event){return _vm.setSelectedFilter('validatedStatus', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({staticClass:"ma-1",attrs:{"small":"","input-value":data.selected},on:{"click":data.select,"click:close":function($event){_vm.setSelectedFilter(
                          'validatedStatus',
                          _vm.removeOnList(
                            _vm.Offers.state.selectedFilters.validatedStatus,
                            data.item.value
                          )
                        )}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,false,3374734010),model:{value:(_vm.Offers.state.selectedFilters.validatedStatus),callback:function ($$v) {_vm.$set(_vm.Offers.state.selectedFilters, "validatedStatus", $$v)},expression:"Offers.state.selectedFilters.validatedStatus"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0 pr-7 pb-6"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.onApplyFilters()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.Icons.send))]),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t('commons.filter.applyFilters')))])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }